import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from '../../components/layout';

const OficinasPage = ({data, ...props}) => {
  const pageTitle = "Oficinas de Contação de Histórias";
  const keywords = "Oficinas de Contação de Histórias, Leituras no Litoral";
  const description = "Oficinas de Contação de Histórias do projeto Leituras no Litoral";

  const hasEvents = false;

  const events = () => {
    return (
      <div>
        {
          categoryDescription()
        }

        <div className='row u-margin-top'>
          {
            data.allMdx.nodes.map((node) => {
              const image = getImage(node.frontmatter.hero_image);
              const imageAlt = node.frontmatter.hero_image_alt;
              const to = `/oficinas/${node.slug}`;
              const title = node.frontmatter.title;
              const date = node.frontmatter.date;
              const time = node.frontmatter.time;
              const location = node.frontmatter.location;
              const classification = node.frontmatter.classification;

              return (
                <div className='col-xs-12 col-sm-6' key={node.id}>
                  <article className='event-card'>
                    <Link
                      to={to}
                      title={title}
                    >
                      <div className='event-card__img'>
                        <GatsbyImage
                          image={image}
                          alt={imageAlt}
                          title={imageAlt}
                        />
                      </div>

                      <div className='event-card__body'>
                        <h2>
                          {title}
                        </h2>

                        <ul className='u-reset-list'>
                          <li><strong>Data:</strong> {date}</li>            
                          <li><strong>Horário:</strong> {time}</li>            
                          <li><strong>Local:</strong> {location}</li>
                          <li><strong>Classificação indicativa:</strong> {classification}</li>
                        </ul>

                      </div>
                    </Link>
                  </article>
                </div>
              );
            })
          }
        </div>
      </div>
    );
  };

  const categoryDescription = () => {
    return (
      <div className='row'>
        <div className='col-xs-12'>
          <div className='content-card u-margin-top'>
            <div className='content-card__body'>
              <h2>Sobre</h2>

              <p>A oficina de contação de histórias, denominada <strong>No caminho das histórias</strong>, será conduzida pela artista <strong>Cleo Cavalcantty</strong>.</p>

              <p>
                Destinada a professoras, educadores, pedagogas, idosos, artistas e demais interessados no tema, <strong>a oficina almeja a descoberta do “Eu, narrador” a partir de pesquisas antropológicas, exercícios de escrita criativa e experimentações</strong> sobre a voz autoral de cada narrador de histórias.
              </p>

              <p>Toda história é única, embora já tenha sido contada de diversas formas de geração em geração até chegar aos nossos ouvidos.</p>

              <p>Do mesmo modo, podemos dizer que cada narrador de histórias também é único. Ninguém conta ou escreve uma história da mesma forma. Cada um de nós é composto por uma vasta trilha de histórias e memórias. Entender esse processo, suas referências, sua herança afetiva e cultural, faz com que se tenha propriedade tanto no narrar quanto na escrita.</p>
            </div>
          </div>

          <div className="wave wave--left wave--2"></div>

          <div className='content-card u-margin-top'>
            <div className='content-card__body'>
              <h2>Cleo Cavalcantty</h2>

              <p>Atriz, contadora de histórias, mediadora de leitura.</p>
              
              <p>Fundadora, em 2006, da Cia Girolê Produções Artísticas, juntamente com Luana Godin e Caroline Casagrande, com foco na narração oral.</p>

              <p>Desde 2012 conduz o Projeto “Sobre lendas e mulheres” que consiste numa peça com mesmo nome, baseado na obra de Clarissa Pínkola Eztes, que aborda o universo feminino. </p>

              <p>Ministrou diversas oficinas para professores e alunos em escolas da rede pública e particular de Curitiba e no Paraná e também em Instituições como o SESC/PR e SESI/PR.</p>

              <p>Em 2020 lançou um livro de coletânea de escrita feminina durante a pandemia chamado “Histórias Quarentenadas” a partir da oficina de escrita feminina Mulheres criam, mulheres narram.</p>
            </div>
          </div>

          <div className="wave wave--left wave--1"></div>

          <div className='content-card u-margin-top'>
            <div className='content-card__body'>
              <h2>No caminho das histórias</h2>

              <ul className='u-reset-list'>
                <li><strong>Data:</strong> 04/11</li>            
                <li><strong>Horário:</strong> 09h às 16h</li>            
                <li><strong>Local:</strong> Casa da Cultura Profº Délcio Ramos - Matinhos/PR</li>
                <li><strong>Endereço:</strong> Calçadão Central</li>
                <li><strong>Inscrição:</strong> <a href="https://forms.gle/mqPmHztF8i8NvZpH6" target='_blank'>Inscreva-se aqui</a></li>
                <li><strong>Evento gratuito</strong></li>
              </ul>

              <div className='u-margin-top'>
                <ul className='u-reset-list'>
                  <li><strong>Data:</strong> 11/11</li>            
                  <li><strong>Horário:</strong> 09h às 16h</li>            
                  <li><strong>Local:</strong> Casa da Cultura - Pontal do Paraná/PR</li>
                  <li><strong>Endereço:</strong> Av. Tom Jobim - Balneário Primavera</li>
                  <li><strong>Inscrição:</strong> <a href="https://forms.gle/Gdvd5yxTXyvMjuKj6" target='_blank'>Inscreva-se aqui</a></li>
                  <li><strong>Evento gratuito</strong></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Layout
      pageTitle={pageTitle}
      keywords={keywords}
      description={description}
      {...props}
    >
      {
        hasEvents ? events() : categoryDescription()
      }
    </Layout>
  )
};

// export const query = graphql`
//   query {
//     allMdx(
//       sort: {fields: frontmatter___date, order: ASC}
//       filter: {fileAbsolutePath: {regex: "/(oficinas)/"}}
//     ) {
//       nodes {
//         frontmatter {
//           title
//           time
//           location
//           classification
//           date(formatString: "DD MMMM, YYYY", locale: "PT-BR")
//           hero_image {
//             childImageSharp {
//               gatsbyImageData(
//                 width: 700
//                 aspectRatio: 1.33
//                 transformOptions: {
//                   fit: COVER
//                   cropFocus: ATTENTION
//                 }
//               )
//             }
//           }
//           hero_image_alt
//         }
//         id
//         slug
//       }
//     }
//   }
// `;

export default OficinasPage;